<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import type { ComputedRef, ConcreteComponent } from 'vue'
import { Iconify } from '~/types/assets'

import { useRoot } from '~/store'

const Root = useRoot()

const { closeModal } = Root

const { modal, modalType } = storeToRefs(Root)

const component: ComputedRef<ConcreteComponent | null> = computed(() =>
  modal.value ? markRaw(resolveComponent(`${modal.value}`) as ConcreteComponent) : null
)
</script>

<template lang="pug">
.modal
  Transition(name="fade-top")
    .modal__wrapper(
      v-if="modal"
      :class="modalType === 'default' ? 'z-30' : 'z-[10000]'"
    )
      section.modal__outer(
        :class="modalType === 'default' ? 'bg-[rgba(0,0,0,0.9)]' : 'bg-black'"
      )
        .modal__inner
          .modal__component
            Component(:is="component")
      .modal__toggler(
        v-show="modalType !== 'absolute'"
        @click="closeModal()"
      )
        Icon(:name="Iconify.close")
</template>

<style lang="sass" scoped>
.modal
  .modal__wrapper
    @apply fixed top-0 right-0 left-0 bottom-0 border-2 border-primary
    section.modal__outer
      @apply w-full h-full scrollbar-primary
      .modal__inner
        @apply w-full h-full relative
        .modal__component
          @apply w-full h-full flex items-center justify-center
    .modal__toggler
      @apply fixed top-0 left-[50%] translate-x-[-50%] w-fit py-2 px-12 z-50 bg-primary text-dark-600 hover:bg-dark-600 hover:text-primary
      clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%)
      span.iconify
        @apply bg-black text-2xl p-0 m-0
</style>
