<script lang="ts" setup>
import type { Context } from 'node:vm'
import { useWindowSize } from '@vueuse/core'
import { vScroll } from '@vueuse/components'
import { useGtag } from 'vue-gtag-next'
import type { UseScrollReturn } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import greekAlphabet from '~/config/greek-alphabet'
import { useRoot } from '~/store/index'
import { useRadar } from '~/store/radar'
import { Iconify } from '~/types/assets'
import { CountryFlag, ScrollDirection } from '~/types/custom'
import type { GreekLetter } from '~/types/custom'

const props = defineProps({
  isTest: {
    type: Boolean,
    required: false,
    default: false
  }
})

const radar = useRadar()
const root = useRoot()
const i18n = useI18n()

const { syncScroll, openToast, openModal } = root

const { transition } = storeToRefs(radar)

const { scrollDirection, toast, scrollEnabled } = storeToRefs(root)

const { event } = useGtag()

const scrollRoot = ref<HTMLElement | null>(null)
const { y } = useScroll(scrollRoot, { behavior: 'smooth' })
const { height, width } = useWindowSize()

const pageLoading = ref(false)

i18n.onBeforeLanguageSwitch = async (
  oldLocale: string,
  newLocale: string,
  isInitialSetup: boolean,
  context: Context
) => {}

i18n.onLanguageSwitched = (oldLocale: string, newLocale: string) => {
  if (oldLocale) {
    const toast = useToast(`${i18n.t('toasts.loaded-lang')} ${newLocale}`, Iconify.alert)
    openToast(toast)
  }
  return new Promise(() => {})
}

function onScroll(e: UseScrollReturn) {
  if (scrollRoot?.value?.clientHeight && scrollEnabled.value) {
    syncScroll(e)
    const half =
      scrollDirection.value === ScrollDirection.top
        ? scrollRoot.value?.clientHeight / 0.25
        : scrollRoot.value?.clientHeight / 4
    const overscroll = e.y.value % scrollRoot.value?.clientHeight
    const borderWidth = 8
    let offset = Math.trunc(e.y.value / scrollRoot.value?.clientHeight)

    offset += overscroll > half ? 1 : 0

    if (!e.isScrolling.value) {
      y.value = offset * (scrollRoot.value?.clientHeight + borderWidth)
      radar.setSection(offset)
    }
  }
}

function scrollSection() {
  const activeOffset = Math.trunc(y.value / height.value)
  y.value = (activeOffset + 1) * height.value
  scrollDirection.value = ScrollDirection.bottom

  event('scroll_section', {
    event_category: 'layout',
    event_label: `section_${greekAlphabet.find((letter: GreekLetter) => letter.index === activeOffset)?.word}`
  })
}

async function onEnter(_page: HTMLElement) {
  pageLoading.value = false
  await i18n.finalizePendingLocaleChange()
}

function onLeave(_page: HTMLElement) {
  pageLoading.value = true
  y.value = 0
}

onMounted(() => {
  if (import.meta.browser && width.value > 640 && width.value < 1024) openModal('ModalTabletWarning')
})
</script>

<template lang="pug">
#root
  .layout__default
    LayoutHeaderDesktop(
      @on-section-scroll="scrollSection"
    )
    LayoutHeaderMobile
    Modal
    main.border-primary(
      ref="scrollRoot"
      v-scroll="onScroll"
    )
      LayoutController(position="top")
      LayoutController(position="left")
      .container-fluid
        .row
          div(class="col-12 md:col-10 md:offset-1 lg:col-8 lg:offset-2")
            Transition(name='fade')
              .page-loader(v-if="pageLoading") 
                Icon(:name="Iconify.gear")
            RouterView(v-if="!isTest" v-slot="route")
              Transition(
                :name="transition"
                mode="out-in"
                @enter="onEnter"
                @leave="onLeave"
              )
                Component(
                  :is="route?.Component",
                  :key="$route.fullPath"
                )
            // Vitest hasn't RouterView
            slot(v-else)
        LayoutController(position="right")
        LayoutController(position="bottom")
        LayoutCookies
        LayoutRights
        UIToast
    LayoutFooterDesktop
    LayoutFooterMobile
</template>

<style lang="sass" scoped>
#root
  @apply bg-dark-300 dark:bg-dark-900 relative
  .layout__default
    @apply overflow-hidden h-[100dvh] bg-circuit
    main
      @apply border-4 max-h-[100dvh] overflow-y-auto overflow-x-hidden scrollbar-primary
      .page-loader
        @apply fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-50
        span.iconify
          @apply animate-spin bg-primary text-7xl
    .radar-desktop
      @apply hidden lg:block fixed top-1 left-1 shadow-2xl
</style>
