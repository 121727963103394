<script lang="ts" setup>
import type { Icon, LordIcon } from '~/types/assets'
import { LordIconSource, LordIconTrigger, Iconify } from '~/types/assets'
import { useRoot } from '~/store'

const { setTheme, openModal } = useRoot()

const { t } = useI18n()

const socialIcons: Array<Icon & { title: string; ariaLabel: string }> = [
  {
    src: Iconify.github,
    name: 'GitHub',
    url: 'https://github.com/LorenzoRottigni/',
    tooltip: 'GitHub | Lorenzo Rottigni',
    title: t('footer.socials.anchors.github.title'),
    ariaLabel: t('footer.socials.anchors.github.aria-label')
  },
  {
    src: Iconify.gitlab,
    name: 'GitLab',
    url: 'https://gitlab.com/LorenzoRottigni/',
    tooltip: 'GitLab | Lorenzo Rottigni',
    title: t('footer.socials.anchors.gitlab.title'),
    ariaLabel: t('footer.socials.anchors.gitlab.aria-label')
  },
  {
    src: Iconify.linkedin,
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/lorenzo-rottigni-ba402b222/',
    tooltip: 'LinkedIn | Lorenzo Rottigni',
    title: t('footer.socials.anchors.linkedin.title'),
    ariaLabel: t('footer.socials.anchors.linkedin.aria-label')
  },
  {
    src: Iconify.instagram,
    name: 'Instagram',
    url: 'https://www.instagram.com/lorenzorottigni/',
    tooltip: 'Instagram | Lorenzo Rottigni',
    title: t('footer.socials.anchors.instagram.title'),
    ariaLabel: t('footer.socials.anchors.instagram.aria-label')
  }
]

const analyticsIcon: LordIcon = {
  src: LordIconSource?.analytics,
  trigger: LordIconTrigger?.loop,
  revert: true,
  delay: 500,
  size: 65,
  loading: 'lazy'
}

function shutDown() {
  setTheme(null)
  openModal('LazyModalOffline')
}

function analyzePage() {
  openModal('LazyModalReport')
}
</script>

<template lang="pug">
article.footer__socials
  ul
    li(
      v-for="(icon, index) in socialIcons"
      :key="`social-icon${index}`"
      v-tooltip="icon.tooltip"
      data-test="social-icon"
    )
      a(
        :href="icon.url",
        target="_blank"
        rel="nofollow"
        :title="icon.title"
        :aria-label="icon.ariaLabel"
      )
        Icon(:name="icon.src")
  .page-report(
    v-tooltip="$t('footer.socials.anchors.lighthouse.tooltip')"
    :title="$t('footer.socials.anchors.lighthouse.title')"
    :aria-label="$t('footer.socials.anchors.lighthouse.aria-label')"
    data-test="lighthouse-report"
    @click="analyzePage"
  )
    IconLord(v-bind="analyticsIcon")
  aside(
    v-tooltip="'Power off systems'"
    data-test="power-off"
    @click="shutDown"
  )
    Icon(:name="Iconify.power")
</template>

<style lang="sass" scoped>
article.footer__socials
  @apply w-full flex p-0
  ul
    @apply w-full flex items-center justify-center xl:justify-end gap-6 p-2
    li
      @apply w-fit h-fit text-white dark:text-secondary px-1.5 xl:px-2.5 xl:py-1.5 bg-light-400 dark:bg-dark-900 pt-2
      clip-path: polygon(15% 0, 85% 0, 100% 50%, 85% 100%, 15% 100%, 0% 50%)
      i
        font-size: 1.7em
      a
        @apply p-0 m-0 block w-fit h-fit
        transition: all .5s
        span.iconify
          @apply text-2xl lg:text-3xl bg-secondary
      &:hover a
        transform: rotate(360deg)
  .page-report
    @apply hidden xl:block cursor-pointer
  aside
    @apply hidden 2xl:flex items-center justify-center bg-primary text-light-100 dark:text-dark-900 pl-8 pr-4 cursor-pointer
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%)
</style>
